.App {
  text-align: center;
  width:100vw;
  height: 100vh;
}


.navbar {
  width: 100vw;
  height: 10vh;
  background-color: rgba(0,0,0,0.3);
  
}

.navList {
  width:100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding:0 50px;
}

.navList li a{
  margin: 0 30px;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.content-list {
  width: 300px;
  float: left;
  margin: 20px;
  text-align: left;
}

.content-list img {
  width:100%;
}